<template>
      <div class="flex justify-center items-center ">
        <div class="w-1/3 h-0.5 bg-white shadow-glow mx-2"></div>
    <img class="animate-swing  w-24 h-24" src="@/assets/balon.png">
    <div class="w-1/3 h-0.5 bg-white shadow-glow mx-2"></div>
  </div>
</template>
<script>
  export default {
    name: 'HeaderEquipo'
  }
  </script>
<style>
    
    @keyframes swing {
        0% { transform: rotate(0deg); }
        25% { transform: rotate(180deg); }
        75% { transform: rotate(-180deg); }
        100% { transform: rotate(0deg); }
    }
  /* Estilo para la sombra de resplandor */
  .shadow-glow {
    box-shadow: 0 0 8px white;
  }
</style>