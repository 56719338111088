<template>
    <div class="hello p-1 flex flex-wrap items-center justify-center">
        <swiper
    :slides-per-view="2"  
    :space-between="1"   
    :breakpoints="{
      320: { slidesPerView: 1, spaceBetween: 1 },  // Configuración para móviles
      768: { slidesPerView: 2, spaceBetween: 1 },  // Configuración para tablets
    }"
    :cssMode="true"
    :navigation="true"
    :mousewheel="true"
    :keyboard="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="(player, index) in shuffledTeam2" :key="index">
      <div
        @click="toggleVisibility(player)"
        class="flex-shrink-0 m-10 relative overflow-hidden bg-black-700 rounded-lg max-w-xs shadow-lg group"
      >
      <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
            style="opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
        </svg>
        <div class="relative pt-10 px-10 flex items-center justify-center group-hover:scale-150 transition-transform">
          <div class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3" style="background: radial-gradient(black, transparent 60%); transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1); opacity: 0.2;"></div>
          <img class="relative w-60" :src="player.image" :alt="player.name">
        </div>
        <div class="relative text-white px-6 pb-6 mt-6">
          <span class="block font-semibold text-xl m-10">{{ player.name }}</span>
          <div v-show="player.visible" id="ocultar">
            <div class="flex justify-between">
              <span class="block font-semibold text-xl">Edad</span>
              <span class="block font-semibold bg-white rounded-full text-green-900 text-xs font-bold px-3 py-2 leading-none flex items-center">{{ player.age }}</span>
            </div>
            <div class="flex justify-between mt-2">
              <span class="block font-semibold text-xl">Estatura</span>
              <span class="block font-semibold bg-white rounded-full text-green-900 text-xs font-bold px-3 py-2 leading-none flex items-center">{{ player.height.toFixed(2) }} m</span>
            </div>
            <div class="flex justify-between mt-2">
              <span class="block font-semibold text-xl">Posición</span>
              <span class="block font-semibold bg-white rounded-full text-green-900 text-xs font-bold px-3 py-2 leading-none flex items-center">{{ player.position }}</span>
            </div>
            <div class="flex justify-between mt-2">
              <span class="block font-semibold text-xl">Institución</span>
              <span class="block font-semibold bg-white rounded-full text-green-900 text-xs font-bold px-3 py-2 leading-none flex items-center">{{ player.institution }}</span>
            </div>

            <div class="flex justify-between mt-2">
              <span class="block font-semibold text-xl">Redes Sociales</span>
              <span class="block font-semibold bg-white rounded-full text-green-900 text-xs font-bold px-3 py-2 leading-none flex items-center"> <a :href="`https://www.instagram.com/${player.socialMedia.instagram}`" target="_blank"><font-awesome-icon :icon="['fab', 'instagram-square']"  class="fa-2x" /></a></span>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
    </div>
  </template>
  
  <script>



import imagen11 from '@/assets/equipo/mateo.png';
import imagen12 from '@/assets/equipo/frank.png';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

  export default {
    name: "CuerpoTecnico",
    components: {
    Swiper,
    SwiperSlide
  },
  setup() {
      return {
        modules: [Navigation, Pagination, Mousewheel, Keyboard],
      };
    },
    data() {
      return {
       
        tecnicos: [
        {
            "image": imagen11,
            "name": "Mateo Estiven Barrios Pinzon",
            "age": 23,
            "height": 1.68,
            "position": "Entrenador",
            "institution": "Santo Domingo Savio",
            "socialMedia": {
                "instagram": "mateo_barrios"
            }
            },
            {
            "image": imagen12,
            "name": "Francisco Cerquera Urrego",
            "age": 21,
            "height": 1.80,
            "position": "Asistente Técnico",
            "institution": "Santo Domingo Savio",
            "socialMedia": {
                "instagram": "Franciscocuf_08"
            }
            },
          ],
        shuffledTeam2: []
      };
    },
    mounted() {
    this.shuffledTeam2 = this.shuffleArray([...this.tecnicos]);
  },
    methods: {
      toggleVisibility(player) {
        player.visible = !player.visible;  // Cambia directamente la propiedad visible del jugador
      },
      shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Intercambio de elementos
      }
      return array;
    }
    }
  }
  </script>

<style>
.swiper-button-prev::after,
.swiper-button-next::after {
    color: #fff;
}

</style>
