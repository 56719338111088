<template>
    <div class="hello p-1 flex flex-wrap items-center justify-center">
        <swiper
    :slides-per-view="4"  
    :space-between="1"   
    :breakpoints="{
      320: { slidesPerView: 1, spaceBetween: 1 },  // Configuración para móviles
      768: { slidesPerView: 2, spaceBetween: 1 },  // Configuración para tablets
      1024: { slidesPerView: 4, spaceBetween: 1 }  // Configuración para pantallas más grandes
    }"
    :cssMode="true"
    :navigation="true"
    :mousewheel="true"
    :keyboard="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="(player, index) in shuffledTeam2" :key="index">
        
        <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
            style="opacity: 0.1;">
            <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
            <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
        </svg>
                  <div class="mb-[18px] flex items-center gap-[2px]">
                    <img
                      src="@/assets/template/images/testimonials/icon-star.svg"
                      alt="star icon"
                    />
                    <img
                      src="@/assets/template/images/testimonials/icon-star.svg"
                      alt="star icon"
                    />
                    <img
                      src="@/assets/template/images/testimonials/icon-star.svg"
                      alt="star icon"
                    />
                    <img
                      src="@/assets/template/images/testimonials/icon-star.svg"
                      alt="star icon"
                    />
                    <img
                      src="@/assets/template/images/testimonials/icon-star.svg"
                      alt="star icon"
                    />
                  </div>

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    “{{ player.mensaje }}’’
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                      <img
                      :src="player.image"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                        {{ player.name  }}
                      </h3>
                      <p class="text-xs text-white">{{ player.tipo }}</p>
                    </div>
                  </a>
                </div>
    </swiper-slide>
  </swiper>
    </div>
  </template>
  
  <script>



import imagen11 from '@/assets/template/images/testimonials/mama.jpg';
import imagen12 from '@/assets/template/images/testimonials/papa.jpg';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

  export default {
    name: "PadresFamilia",
    components: {
    Swiper,
    SwiperSlide
  },
  setup() {
      return {
        modules: [Navigation, Pagination, Mousewheel, Keyboard],
      };
    },
    data() {
      return {
       
        tecnicos: [
            {
            "image": imagen12,
            "name": "Gerson Blanco",
            "tipo": "Papá Diego Andrés Blanco Ospina",
            "mensaje": "Recuerda que cada saque, cada volea y cada punto cuentan. Confía en ti mismo y en tu entrenamiento. ¡Estás listo para esto!",
           
            },
            {
            "image": imagen12,
            "name": "Luis Rodriguez",
            "tipo": "Papá Andrés Felipe Rodríguez Vidales",
            "mensaje": "No importa el resultado, estoy increíblemente orgulloso de todo el esfuerzo y dedicación que has mostrado. ¡Sal ahí y disfruta el juego!",
            },
            {
            "image": imagen11,
            "name": "Marly Castañeda",
            "tipo": "Mamá Cristian Nicolás Mendez Castañeda",
            "mensaje": "Concéntrate en el juego, juega como sabes y deja todo en la cancha. Estoy aquí para apoyarte en cada momento.",
            },
            {
            "image": imagen11,
            "name": "Fernanda Hernández",
            "tipo": "Mamá David Santiago Acosta Hernández",
            "mensaje": "Tú y tu equipo han trabajado duro para llegar hasta aquí. Confía en tus compañeros y jueguen como una unidad. ¡Ustedes pueden hacerlo!",
            },
            {
            "image": imagen12,
            "name": "Juan Manuel Rozo",
            "tipo": "Papá Jhoner Felipe Rozo Ramirez",
            "mensaje": "Respira hondo, mantén la calma y recuerda por qué amas este deporte. Esa pasión te llevará lejos en el juego",
            },
            {
            "image": imagen12,
            "name": "Wilfredo Gutiérrez",
            "tipo": "Papá David Felipe Gutiérrez Mahecha",
            "mensaje": "Hoy es un día para brillar. Muestra al mundo de lo que eres capaz. ¡Vamos con todo!",
            },
            {
            "image": imagen11,
            "name": "Leidy Carolina Vega",
            "tipo": "Mamá Andrey Santiago Tejada Vega",
            "mensaje": "La verdadera victoria está en dar lo mejor de ti mismo. Siempre estaré orgulloso de ti, pase lo que pase.",
            },
            {
            "image": imagen11,
            "name": "Nohora Roldán",
            "tipo": "Mamá Harrison Stiven Pérez Roldán",
            "mensaje": "Mira lo lejos que has llegado. Esto no es solo un juego, es la muestra de tu crecimiento y esfuerzo. Estoy aquí para verte triunfar.",
            },
            {
            "image": imagen12,
            "name": "Luis Rey",
            "tipo": "Papá Juan Diego Rey Villegas",
            "mensaje": "Confía en tus habilidades y juega con confianza. Has entrenado para este momento. ¡Es tu tiempo de brillar!",
            },
            {
            "image": imagen12,
            "name": "Jhon García",
            "tipo": "Papá Johis Alejandro García Mendieta",
            "mensaje": "Cada punto es una nueva oportunidad. No te desanimes por los errores; aprende de ellos y sigue adelante. ¡Tú puedes hacerlo!",
            },
            
          ],
        shuffledTeam2: []
      };
    },
    mounted() {
    this.shuffledTeam2 = this.shuffleArray([...this.tecnicos]);
  },
    methods: {
      toggleVisibility(player) {
        player.visible = !player.visible;  // Cambia directamente la propiedad visible del jugador
      },
      shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Intercambio de elementos
      }
      return array;
    }
    }
  }
  </script>

<style>
.swiper-button-prev::after,
.swiper-button-next::after {
    color: #fff;
}

</style>
