import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles/index.css';
import './assets/template/css/tailwind.css';
import 'animate.css';


// Importa Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'; // Iconos sólidos
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'; // Icono de marca
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Añade los iconos al 'library'
library.add(faUserSecret, faInstagramSquare);

// Crea la instancia de la aplicación
const app = createApp(App);

// Registra el componente de FontAwesome
app.component('font-awesome-icon', FontAwesomeIcon);

// Monta la aplicación
app.mount('#app');
