<template>
    <div class="hello p-1 flex flex-wrap items-center justify-center">

        
        <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style="opacity: 0.1;">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                 

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    LLegada Delegación
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                        <img src="@/assets/llegada.webp"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                      Domingo<br> 2 de Junio del 2024
                      </h3>
                      <p class="text-xs text-white">Valledupar, Colombia   </p>
                    </div>
                  </a>
                </div>

                <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style="opacity: 0.1;">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                 

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    Partido de Competencia
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                        <img src="@/assets/versus.webp"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                      Lunes<br>3 de Junio del 2024
                      </h3>
                      <p class="text-xs text-white">Valledupar, Colombia   </p>
                    </div>
                  </a>
                </div>

                <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style="opacity: 0.1;">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                 

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    Partido de Competencia
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                        <img src="@/assets/versus.webp"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                      Martes<br> 4 de Junio del 2024
                      </h3>
                      <p class="text-xs text-white">Valledupar, Colombia   </p>
                    </div>
                  </a>
                </div>

                <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style="opacity: 0.1;">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                 

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    Partido de Competencia
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                        <img src="@/assets/versus.webp"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                      Miércoles<br> 5 de Junio del 2024
                      </h3>
                      <p class="text-xs text-white">Valledupar, Colombia   </p>
                    </div>
                  </a>
                </div>

                <div
                  class="rounded-xl relative overflow-hidden px-4 py-[30px] shadow-testimonial dark:bg-dark sm:px-[30px] rounded-lg max-w-xs m-10"
                >
                <svg class="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform" viewBox="0 0 375 283" fill="none"
                    style="opacity: 0.1;">
                    <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
                    <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
                </svg>
                 

                  <p class="mb-6 text-base text-white dark:text-white  font-semibold ">
                    Competencia y Salida delegación
                  </p>

                  <a href="#" class="flex items-center gap-4">
                    <div class="h-[50px] w-[50px] overflow-hidden rounded-full">
                        <img src="@/assets/salida.webp"
                        alt="author"
                        class="h-[50px] w-[50px] overflow-hidden rounded-full"
                      />
                    </div>

                    <div>
                      <h3
                        class="text-sm font-semibold text-white dark:text-white"
                      >
                      Jueves <br> 6 de Junio del 2024
                      </h3>
                      <p class="text-xs text-white">Valledupar, Colombia   </p>
                    </div>
                  </a>
                </div>

    </div>
  </template>
  
  <script>


  export default {
    name: "CronogramaJuegos",
  }
  </script>

<style>
.swiper-button-prev::after,
.swiper-button-next::after {
    color: #fff;
}

</style>
